<template>
  <div class="w-screen">
    <section>
      <Banner />
    </section>
    <section>
      <Trailer :playerState="playerState" />
    </section>
    <section>
      <About />
    </section>
    <section>
      <AboutGames />
    </section>
    <section>
      <TokenomicsG />
    </section>
    <section>
      <RoadMap />
    </section>
    <section>
      <PartnerList />
    </section>
    <section>
      <AboutC4KMedia />
    </section>
  </div>
</template>

<style></style>


<script>
// @ is an alias to /src
import Banner from '@/components/home/Banner.vue'
import Trailer from '@/components/home/VideoTrailer.vue'
import About from '@/components/home/About.vue'
import AboutGames from '@/components/home/AboutGames.vue'
import TokenomicsG from '@/components/home/Tokenomics.vue'
import RoadMap from '@/components/home/Roadmap.vue'
import PartnerList from '@/components/home/Partner.vue'
import AboutC4KMedia from '@/components/home/AboutC4KMedia.vue'
import { onMounted, onBeforeUnmount, watch, ref, inject } from 'vue';
import 'intersection-observer';

export default {
  name: 'HomeView',
  components: {
    Banner,
    Trailer,
    About,
    AboutGames,
    TokenomicsG,
    RoadMap,
    PartnerList,
    AboutC4KMedia
  }, props: ['parentCurrentSection', 'activeScroll'],

  methods: {

  },


  setup(props) {

    let currentSection = Number(window.sessionStorage.getItem('currentSection')) || 0;
    let isActiveScroll = false;
    const isLoadingGlobal = inject('isLoadingGlobal');

    watch(isLoadingGlobal, (newValue,) => {
      isActiveScroll = !newValue;
    });

    watch(() => props.parentCurrentSection, (currentSectionValue) => {
      currentSection = currentSectionValue;
    });

    watch(() => props.activeScroll, (scrollState) => {
      isActiveScroll = scrollState;
    });

    function isDestop() {
      return window.innerWidth > window.innerHeight;
    }

    function handleBannerAnimations(active) {
      var rocks = document.getElementById("rocks");
      var beach = document.getElementById("beach");
      var title = document.getElementById("title");

      if (rocks && beach && title) {
        if (active) {
          rocks.style.transform = "translateY(0)";
          setTimeout(function () {
            beach.style.transform = 'translateY(-5dvh)';
          }, 100);
          setTimeout(function () {
            beach.style.transform = 'translateY(0)';
          }, 1200);
          setTimeout(function () {
            title.style.transform = 'translateY(0)';
          }, 500);
        } else {
          rocks.style.transform = "translateY(100dvh)";
          beach.style.transform = 'translateY(100dvh)';
          title.style.transform = 'translateY(-100dvh)';
        }
      }

    }

    function handleAboutUsAnimations(active) {
      const model_3d = document.getElementById('model_3d');
      const title_about_desktop = document.getElementById('title_about_desktop');
      const title_about_mobile = document.getElementById('title_about_mobile');
      const data_analytic = document.getElementById('data_analytic');
      const playOnTelegramBtn = document.getElementById('playOnTelegramBtn');

      if (isDestop()) {
        if (model_3d && title_about_desktop && data_analytic && playOnTelegramBtn) {
          if (active) {
            model_3d.style.transform = 'translateY(0)';
            title_about_desktop.style.transform = 'translateX(0)';
            data_analytic.style.transform = 'translateX(0)';
            playOnTelegramBtn.style.transform = 'translateX(0)';
          } else {
            model_3d.style.transform = 'translateY(100dvh)';
            title_about_desktop.style.transform = 'translateX(-100vw)';
            data_analytic.style.transform = 'translateX(100vw)';
            playOnTelegramBtn.style.transform = 'translateX(100vw)';
          }
        }
      } else {
        if (model_3d && title_about_mobile) {
          if (active) {
            model_3d.style.transform = 'translateY(0)';
            title_about_mobile.style.transform = 'translateY(0)';
          } else {
            model_3d.style.transform = 'translateY(100dvh)';
            title_about_mobile.style.transform = 'translateY(-100dvh)';
          }
        }
      }
    }

    function preventWheel(e) {
      e.preventDefault();
    }

    function preventTouchMove(e) {
      e.preventDefault();
    }

    function preventMiddleClick(e) {
      if (e.button == 1) e.preventDefault();
    }


    // window.addEventListener('wheel', (e) => {
    //   e.preventDefault();
    // }, { passive: false });

    // window.addEventListener('touchmove', (e) => {
    //   e.preventDefault();
    // }, { passive: false });

    // window.addEventListener('mousedown', function (e) {
    //   if (e.button == 1) e.preventDefault();
    // }, false);

    let startX, startY;

    let lastExecution = Date.now();


    let longPress = false;
    let pressTimer = null;
    let touchMoveTriggered = false;

    function handleTouchStart(event) {
      if (isActiveScroll) {
        startX = event.touches[0].clientX;
        startY = event.touches[0].clientY;
      }
      pressTimer = window.setTimeout(function () {
        longPress = true;
      }, 300);
    }

    function handleTouchEnd(e) {
      if (pressTimer !== null) {
        window.clearTimeout(pressTimer);
      }
      if (longPress) {
        e.preventDefault();
      }
      longPress = false;
      touchMoveTriggered = false;
    }

    // window.addEventListener('touchend', function (e) {
    //   if (pressTimer !== null) {
    //     window.clearTimeout(pressTimer);
    //   }
    //   if (longPress) {
    //     e.preventDefault();
    //   }
    //   longPress = false;
    //   touchMoveTriggered = false;
    // }, false);

    function handleTouchMove(event) {
      if (isActiveScroll) {
        if (!longPress && !touchMoveTriggered) {
          requestAnimationFrame(() => {
            const sections = document.querySelectorAll("section");
            const touchMovedX = startX - event.touches[0].clientX;
            const touchMovedY = startY - event.touches[0].clientY;
            const isScrollingDown = touchMovedY > 0;
            const isSwipingHorizontally = Math.abs(touchMovedX) > Math.abs(touchMovedY);

            if (Date.now() - lastExecution > 200) {
              if (isSwipingHorizontally) {
                if (touchMovedX > 0) {
                  console.log("Swiped left");
                  // Handle left swipe
                } else {
                  console.log("Swiped right");
                  // Handle right swipe
                }
              } else {
                if (isScrollingDown) {
                  currentSection = Math.min(currentSection + 1, sections.length - 1);
                } else {
                  currentSection = Math.max(currentSection - 1, 0);
                }
                window.scrollTo({
                  top: sections[currentSection].offsetTop,
                  behavior: 'smooth'
                });
              }

              lastExecution = Date.now();
            }
            touchMoveTriggered = true;
          });
        }
      }
    }

    function handleScroll(event) {
      if (isActiveScroll) {
        const sections = document.querySelectorAll("section");

        if (Date.now() - lastExecution > 200) {
          if (event.deltaY > 0) {
            currentSection = Math.min(currentSection + 1, sections.length - 1);
          } else {
            currentSection = Math.max(currentSection - 1, 0);
          }

          window.scrollTo({
            top: sections[currentSection].offsetTop,
            behavior: 'smooth'
          });

          lastExecution = Date.now();
        }
      }
    }

    function disableArrowKeysAndSpace() {
      window.addEventListener('keydown', function (e) {
        if ([32, 38, 40].includes(e.keyCode)) {
          e.preventDefault();
        }
      }, false);
    }

    function goToSectionActive() {
      const navItems = document.querySelectorAll('.nav_item_container');
      const sections = document.querySelectorAll("section");

      navItems.forEach((navItem, index) => {
        navItem.addEventListener('click', () => {
          currentSection = index;
          window.scrollTo({
            top: sections[index].offsetTop,
            behavior: 'smooth'
          });
        });
      });
    }


    function activeRightNav(position) {
      const navItems = document.querySelectorAll('.nav_item');
      if (position >= 0 && position < navItems.length) {
        navItems[position].style.backgroundColor = '#411a0f';
      }
    }

    function deActiveRightNav() {
      const navItems = document.querySelectorAll('.nav_item');
      navItems.forEach(navItem => navItem.style.backgroundColor = '#d1b988');
    }

    function goToTop() {
      if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
      } else {
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        }
      }
    }


    function scrollAndTouchEvent(active) {
      if (active) {
        isActiveScroll = true;
        window.addEventListener('wheel', preventWheel, { passive: false });
        window.addEventListener('touchmove', preventTouchMove, { passive: false });
        window.addEventListener('mousedown', preventMiddleClick, false);
        window.addEventListener('wheel', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd, false);
      } else {
        window.removeEventListener('wheel', preventWheel, { passive: false });
        window.removeEventListener('touchmove', preventTouchMove, { passive: false });
        window.removeEventListener('mousedown', preventMiddleClick, false);
        window.removeEventListener('wheel', handleScroll);
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd, false);
      }
    }

    let playerState = ref(true);
    function playerStateChange(active) {
      playerState.value = active;
    }

    onMounted(() => {

      goToTop();
      disableArrowKeysAndSpace();

      window.addEventListener('resize', function () {
        location.reload();
      });

      scrollAndTouchEvent(true);

      const sections = document.querySelectorAll('section');

      const observer = new IntersectionObserver(
        (entries) => {
          deActiveRightNav();
          entries.forEach((entry) => {
            if (entry.target === sections[0]) {
              if (entry.isIntersecting) {
                setTimeout(function () {
                  activeRightNav(0);
                }, 100);
                handleBannerAnimations(true);
              } else {
                handleBannerAnimations(false);
                deActiveRightNav();
              }
            }
            if (entry.target === sections[1]) {
              if (entry.isIntersecting) {
                activeRightNav(1);
                playerStateChange(true);
              } else {
                deActiveRightNav();
                playerStateChange(false);
              }
            }
            if (entry.target === sections[2]) {
              if (entry.isIntersecting) {
                activeRightNav(2);
                handleAboutUsAnimations(true);
              } else {
                deActiveRightNav();
                handleAboutUsAnimations(false);
              }
            }
            if (entry.target === sections[3]) {
              if (entry.isIntersecting) {
                activeRightNav(3);
              } else {
                deActiveRightNav();
              }
            }
            if (entry.target === sections[4]) {
              if (entry.isIntersecting) {
                activeRightNav(4);
              } else {
                deActiveRightNav();
              }
            }
            if (entry.target === sections[5]) {
              if (entry.isIntersecting) {
                activeRightNav(5);
              } else {
                deActiveRightNav();
              }
            }
            if (entry.target === sections[6]) {
              if (entry.isIntersecting) {
                activeRightNav(6);
              } else {
                deActiveRightNav();
              }
            }
            if (entry.target === sections[7]) {
              if (entry.isIntersecting) {
                activeRightNav(7);
              } else {
                deActiveRightNav();
              }
            }
            if (entry.target === sections[8]) {
              if (entry.isIntersecting) {
                activeRightNav(8);
              } else {
                deActiveRightNav();
              }
            }
          });
        },
        { threshold: 1 }
      );

      sections.forEach((section) => {
        observer.observe(section);
      });

      goToSectionActive();

    });

    onBeforeUnmount(() => {
      scrollAndTouchEvent(false);
    });

    return {
      playerState
    };

  },
}
</script>
