<template>
  <div id="tokenomics" class="w-screen h-screen relative overflow-hidden">
    <div class="h-full w-full flex flex-col justify-center items-center relative">
      <div ref="bg_tokenomics" class="absolute h-full w-full top-0 left-0 bg-cover transition-all duration-700 bg">
      </div>
      <div class="w-full h-[50%] flex justify-center items-center z-10 flex-col">
        <h2 v-if="isLandscape" class="font-caribbean">GRANDNOMICS</h2>
        <img v-else src="./img/tokenomics/mobile/title.webp" class="w-full">
        <div v-if="!isLandscape" class="w-full h-fit flex items-center justify-end">
          <h3 ref="name">AIRDROP 1</h3>
        </div>
      </div>
      <div class="w-full h-[50%] relative">
        <swiper ref="swiper" :pagination="{
          dynamicBullets: true,
        }" :modules="modules" :loop="true" :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }" :speed="1000" class="mySwiper" @slideChangeTransitionStart="onSlideChange" :navigation="true"
          :breakpoints="swiperOptions.breakpoints" @init="onSwiperInit">
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship1.png" class="w-full">
                <h1>AIRDROP 1</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship1.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship2.png" class="w-full">
                <h1>FAIRLAUNCH</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship2.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship3.png" class="w-full">
                <h1>DEX EXCHANGE LISTING</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship3.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship4.png" class="w-full">
                <h1>FARMING</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship4.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship5.png" class="w-full">
                <h1>PARTICIPATION IN THE GAME</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship5.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship6.png" class="w-full">
                <h1>TEAM</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship6.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship7.png" class="w-full">
                <h1>MARKETING</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship7.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="w-full h-full flex justify-center items-center">
              <div v-if="isLandscape" class="w-[60%] h-fit relative ship flex flex-col">
                <img src="./img/tokenomics/ship/ship8.png" class="w-full">
                <h1>CEX EXCHANGE</h1>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h4>?%</h4>
                </div>
              </div>
              <div v-else class="w-[95%] h-fit relative ship flex flex-col mb-[35%]">
                <img src="./img/tokenomics/ship/ship8.png" class="w-full">
                <div class="h-[5%]"></div>
                <div class="absolute w-full aspect-video bottom-0 left-0">
                  <h5>?%</h5>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import bg1 from './img/tokenomics/background/back1.jpg';
import bg2 from './img/tokenomics/background/back2.jpg';
import bg3 from './img/tokenomics/background/back3.jpg';
import bg4 from './img/tokenomics/background/back4.jpg';
import bg5 from './img/tokenomics/background/back5.jpg';
import bg6 from './img/tokenomics/background/back6.jpg';
import bg7 from './img/tokenomics/background/back7.jpg';
import bg8 from './img/tokenomics/background/back8.jpg';
export default {
  name: 'TokenomicsG',
  props: {
    msg: String
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },
  data() {
    return {
      previousIndex: 0,
      bgs: [bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg1],
      names: ['AIRDROP 1', 'FAIRLAUNCH', 'DEX EXCHANGE LISTING', 
        'FARMING', 'PARTICIPATION IN THE GAME',
        'TEAM', 'MARKETING', 'CEX EXCHANGE', 
       ],
      preloadedImages: [],
      swiperOptions: {
        breakpoints: {
          1024: {
            slidesPerView: 1,
          },
          1025: {
            slidesPerView: 3,
          },
        }
      },
    };
  },
  created() {
    this.preloadImages();
  },
  methods: {
    preloadImages() {
      this.bgs.forEach((src, index) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          this.preloadedImages[index] = img;
        };
      });
    },
    onSwiperInit(swiper) {
      if (swiper.params.slidesPerView != 1) {
        const ship = swiper.slides[1].querySelector('.ship');
        ship.style.transform = 'scale(1.5)';
      }else{
        this.bgs = [ bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];
      }
    },
    onSlideChange(swiper) {
      this.currentIndex = swiper.realIndex;
      if (swiper.params.slidesPerView != 1) {
        swiper.slides.forEach((slide, index) => {
          const ship = slide.querySelector('.ship');
          if (ship) {
            if (swiper.activeIndex === index - 1) {
              ship.style.transform = 'scale(1.5)';
            } else {
              ship.style.transform = 'scale(1)';
            }
          }
        });
      }
      const bgElement = this.$refs.bg_tokenomics;
      const name = this.$refs.name;
      if (bgElement && this.bgs[this.currentIndex]) {
        bgElement.style.backgroundImage = `url(${this.bgs[this.currentIndex]})`;
        if(name){
          name.textContent = this.names[this.currentIndex];
        }
      }
    },
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
  background-image: url(./img/tokenomics/background/back2.jpg);
}

.swiper {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.mySwiper {
  overflow: visible !important;
  cursor: pointer;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ship {
  transition: all 0.7s ease;
  transform-origin: bottom;
}

::v-deep .swiper-pagination-bullet {
  background: #f3b842;
}

::v-deep .swiper-pagination-bullet-active {
  background: #ecca82;
}

h1,
h3,
h4,
h5 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin: 0;
  padding: 0;
  color: #d1b988;
  font-style: italic;
  text-shadow: 1px 1px 4px #000000;
}

h1 {
  font-size: 1.3vw;
}

h3 {
  font-size: 6vw;
  margin-right: 2%;
}

h4 {
  font-size: 4vw;
  font-weight: bolder;
  position: absolute;
  right: 10%;
  top: 22%;
}

h5 {
  font-size: 20vw;
  font-weight: bolder;
  position: absolute;
  right: 10%;
  top: 37%;
}

h2 {
  font-size: 6vw;
  font-weight: bolder;
  color: #e3c68f;
  text-shadow: 5px 5px 15px #000000;
  padding-top: 6%;
  cursor: pointer;
}
</style>
