<template>
  <div id="banner" class="w-screen h-screen relative overflow-hidden no-select">
    <div class="w-full h-full -z-50">
      <video ref="video" class="w-full h-full object-cover" autoplay loop muted webkit-playsinline playsinline
        preload="metadata" @loadeddata="mediaLoaded">
        <source v-if="isLandscape" src="../home/video/waveandboat.webm" type="video/webm" controlsList="nodownload"
          disablePictureInPicture>
        <source v-else src="../home/video/backhomemobile.webm" type="video/webm" controlsList="nodownload"
          disablePictureInPicture>
          <source v-if="!isLandscape" src="../home/video/backhomemp4.mp4" type="video/mp4" controlsList="nodownload"
          disablePictureInPicture>
        Your browser does not support the video tag.
      </video>
    </div>
    <div id="title" v-if="isLandscape"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10">
      <div class="w-[60%] h-fit mb-[12%] relative">
        <img src="./img/lightgrandjouneyleft.png"
          class="light absolute left-0 top-0 h-full z-0 scale-[3] origin-bottom">
        <img src="./img/lightgrandjouneyright.png"
          class="light2 absolute right-0 top-0 h-full z-20 scale-[3] origin-top">
        <img class="w-full z-10 relative" src="../home/img/grandjouney.png" @load="mediaLoaded">
      </div>
    </div>
    <div id="title" v-else class="absolute top-0 left-0 w-full h-full flex justify-center items-start pt-[15%] z-10">
      <img src="../home/img/mobile/titlehomemobilefix.webp" class="w-full">
    </div>
    <div id="beach" v-if="isLandscape" class="absolute left-0 w-full h-full flex justify-center items-end">
      <img class="w-full" src="../home/img/beach.png" @load="mediaLoaded">
    </div>
    <div id="beach" v-else class="absolute left-0 w-full h-full flex justify-center items-end">
      <img class="w-full" src="../home/img/mobile/island.png">
    </div>
    <div id="rocks" v-if="isLandscape" class="absolute top-0 left-0 w-full h-full flex justify-between items-end rock">
      <img src="../home/img/rockleft.png" @load="mediaLoaded">
      <img src="../home/img/rockright.png" @load="mediaLoaded">
    </div>
    <div id="rocks" v-else class="absolute top-0 left-0 w-full h-full flex justify-between items-end">
      <img src="../home/img/mobile/rock.png" class="w-full">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeBanner',
  data() {
    return {
      mediaCount: 6,  // The total number of media items
      loadedMedia: 0,  // The number of media items that have been loaded
    };
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },
  methods: {
    mediaLoaded() {
      this.loadedMedia++;
      if (this.loadedMedia === this.mediaCount) {
        console.log('All media are loaded');
      }
    },
  },
  mounted() {
    if (this.$refs.video.readyState === 4) {  // If the video is already loaded when the component is mounted
      this.loadedMedia++;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .title{
  padding-bottom: 12%;
  gap: calc(100vw * 0.02);
} */

.title img {
  height: calc(100dvh*0.13/var(--devicePixelRatio));
}

.rock img:first-child {
  height: calc(100vw*0.1/var(--devicePixelRatio));
}

.rock img:last-child {
  height: calc(100vw*0.07/var(--devicePixelRatio));
}

#beach {
  bottom: calc(100vw*-0.05);
}

#rocks {
  transition: transform 0.5s ease;
}

#beach {
  transition: transform 1s ease;
  transform: translateY(100dvh);
}

#title {
  transition: transform 1s ease;
  transform: translateY(-100dvh);
}

.light {
  transition: transform 1s ease;
  animation: light-animation-left 3s infinite alternate;
}

.light2 {
  transition: transform 1s ease;
  animation: light-animation-right 3s infinite alternate;
}


@keyframes light-animation-left {
  0% {
    transform: rotate(0) scale(3) translateY(20%);
  }

  50% {
    transform: rotate(5deg) scale(3) translateY(20%);
  }

  100% {
    transform: rotate(0) scale(3) translateY(20%);
  }
}

@keyframes light-animation-right {
  0% {
    transform: rotate(0) scale(3) translateY(-20%) translateX(5%);
  }

  50% {
    transform: rotate(-5deg) scale(3) translateY(-20%) translateX(5%);
  }

  100% {
    transform: rotate(0) scale(3) translateY(-20%) translateX(5%);
  }
}
</style>
