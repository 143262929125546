<template>
    <div id="loading"
        class="backdrop-blur-lg w-full h-full fixed overflow-hidden flex justify-center items-center flex-col"
        style="z-index: 9996;">
        <div class="absolute h-full w-full top-0 left-0">
            <img id="skysunset" src="../assets/img/loading/skysunset.webp" class="absolute left-0 bottom-0 h-full w-full">
            <img id="mountainafterleft" src="../assets/img/loading/mountainafter.webp" class="absolute left-0 bottom-0 h-full w-full">
            <img id="water2" src="../assets/img/loading/mobile/watermobile.webp" class="absolute left-0 bottom-0  h-full w-full">
            <img id="water" src="../assets/img/loading/water.webp" class="absolute left-0 bottom-0  h-full w-full">
            <img id="mountainleft" src="../assets/img/loading/mountainbackleft.webp" class="absolute left-0 bottom-0  h-full w-full">
            <div id="ship" class="absolute w-full h-full flex justify-center items-center">
                <img src="../assets/img/loading/ship.png" class="ship">
            </div>
            <img id="mountainright" src="../assets/img/loading/mountainright.webp" class="absolute left-0 bottom-0  h-full w-full">
            <img id="island" src="../assets/img/loading/island.webp" class="absolute left-0 bottom-0  h-full w-full">
            <img id="rockright" src="../assets/img/loading/rockright.webp" class="absolute left-0 bottom-0 h-full w-full">
            <img id="tree" src="../assets/img/loading/tree.webp" class="absolute left-0 bottom-0 h-full w-full">
            <img id="leaf" src="../assets/img/loading/leaf.webp" class="absolute left-0 top-0 ">
            <div class="absolute h-full w-full top-0 left-0 flex justify-start items-end gap-20">
                <div class="loading_logo relative flex justify-start items-center gap-[calc(100vw*0.01)]">
                    <div class="relative aspect-square h-full">
                        <img src="../assets/img/wheel.svg" class="wheel ">
                        <div class="absolute left-0 top-0 w-full h-full flex justify-center items-center">
                            <img class="w-[65%]" src="../assets/img/logonavbar.png" alt="Logo">
                        </div>
                    </div>
                    <h1 id="loading_text">LOADING 0%</h1>
                </div>
            </div>
        </div>
        <svg>
            <filter id="turbulence" x="0" y="0" width="100%" height="100%">
                <feTurbulence id="sea-filter" numOctaves="10" seed="10" baseFrequency="0.002 0.004"></feTurbulence>
                <feDisplacementMap scale="40" in="SourceGraphic"></feDisplacementMap>
                <animate xlink:href="#sea-filter" attributeName="baseFrequency" dur="30s" keyTimes="0;0.5;1"
                    values="0.002 0.006;0.004 0.008;0.002 0.006" repeatCount="indefinite" />
            </filter>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LoadingPage',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#tree, #leaf{
    transition: all 2s ease;
}
#island, #rockright{
    transition: all 5s ease;
}
#mountainleft, #mountainright, #mountainafterleft, #ship, #skysunset, #water, #water2{
    transition: all 8s ease;
}
.ship {
    width: calc(100vw*0.3);
    animation: ship 3s linear infinite;
    margin-left: calc(100vw*0.2);
}
#skysunset{
    transform: scale(1.5);
}

#water2{
    display: none;
}



@keyframes ship {
    0% {
        transform: rotate(0) translateY(0px);
    }

    25% {
        transform: rotate(-3deg) translateY(5px);
    }

    50% {
        transform: rotate(0deg) translateY(0px);
    }

    75% {
        transform: rotate(3deg) translateY(5px);
    }

    100% {
        transform: rotate(0deg) translateY(0px);
    }
}


#water {
    filter: url("#turbulence");
    -webkit-filter: url("#turbulence");
}

#loading {
    transition: opacity 0.3s ease-out;
    gap: calc(100vw*0.06);
    /* display: none; */
    cursor: wait;
}

.loading_logo {
    height: calc(100vw*0.08);
    width: 100%;
}

.wheel {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    animation: wheel-rotate 5s linear infinite;
}


@keyframes wheel-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#loading_text {
    font-size: calc(100vw * 0.03);
    font-family: "Black Ops One", system-ui;
    font-weight: 400;
    font-style: normal;
    color: #d1a141;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>