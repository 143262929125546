<template>
  <div id="aboutgames" class="w-screen h-screen relative overflow-hidden">
    <img v-if="isLandscape" ref="wheel_about_games" src="./img/about_game/wheel.png"
      class="absolute z-40 bottom-0 left-0 wheel pointer-events-none">
    <video v-if="isLandscape" class="absolute w-full h-full object-cover" autoplay loop muted webkit-playsinline
      playsinline preload="metadata">
      <source src="./video/smoke.webm" type="video/webm">
    </video>
    <swiper ref="swiper" :pagination="{
      dynamicBullets: true,
    }" :modules="modules" :loop="true" :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }" :speed="1000" class="mySwiper" @slideChangeTransitionStart="onSlideChange">
      <swiper-slide>
        <div class="w-full h-full flex justify-center items-center relative">
          <div class="w-full h-full flex flex-col xl:flex-row">
            <div class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-center">
              <div v-if="isLandscape" class="h-full w-[80%] flex flex-col justify-center items-start gap-[1%]">
                <div class="h-[5%]"></div>
                <img class="w-[85%] h-fit" src="../home/img/grandjouney.png">
                <h1 class="desktop_h1">Mini Game Platform</h1>
                <span class="desktop_span">Where every game leads to an airdrop adventure <br>
                  Play and trade using TG Game Bot</span>
                <button class="desktop_button">Explore Game</button>
              </div>
              <div v-else class="h-full w-[90%] flex flex-col justify-center items-center">
                <div class="h-[10%]"></div>
                <img class="w-full h-fit" src="../home/img/grandjouney.png">
                <h1 class="mobile_h1">Mini Game Platform</h1>
                <span class="mobile_span">Where every game leads to an airdrop adventure
                  <br> Play and trade using TG Game Bot</span>
                <button class="mobile_button">Explore Game</button>
                <!-- <div class="h-[10%]"></div> -->
              </div>
            </div>
            <div class="relative w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start">
              <div v-if="isLandscape" class="h-full w-[70%] flex flex-col justify-center items-start gap-[1.5%]">
                <div class="h-[5%]"></div>
                <div class="w-full h-fit flex justify-between items-center">
                  <img src="./img/about_game/6.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  <img src="./img/about_game/2.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                </div>
                <div class="w-full h-fit flex justify-between items-center">
                  <img src="./img/about_game/3.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  <img src="./img/about_game/4.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                </div>
                <div class="w-full h-fit flex justify-between items-center">
                  <img src="./img/about_game/5.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  <img src="./img/about_game/1.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                </div>
              </div>
              <div id="minigame_list" v-else
                class="absolute left-0 h-full w-full flex flex-col justify-start items-center gap-[1.5%]">
                <div class="w-[90%]">
                  <div class="w-full h-fit flex justify-between items-center">
                    <img src="./img/about_game/6.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                    <img src="./img/about_game/2.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  </div>
                  <div class="h-[1.5%]"></div>
                  <div class="w-full h-fit flex justify-between items-center">
                    <img src="./img/about_game/3.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                    <img src="./img/about_game/4.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  </div>
                  <div class="h-[1.5%]"></div>
                  <div class="w-full h-fit flex justify-between items-center">
                    <img src="./img/about_game/5.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                    <img src="./img/about_game/1.jpg" class="w-[49%] border-4 border-[#d1a141] rounded">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="w-full h-full flex justify-center items-center relative">
          <div class="w-full h-full flex flex-col xl:flex-row">
            <div class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start">
              <div v-if="isLandscape" class="h-full w-[80%] flex flex-col justify-center items-start gap-[1%]">
                <div class="h-[5%]"></div>
                <img class="w-[85%] h-fit" src="../home/img/grandjouney.png">
                <h1 class="desktop_h1">Telegram Mini Game</h1>
                <span class="desktop_span">Where every game leads to an airdrop adventure <br>
                  Play and trade using TG Game Bot</span>
                <button class="desktop_button">Explore Game</button>
              </div>
              <div v-else class="h-full w-[90%] flex flex-col justify-center items-center">
                <div class="h-[10%]"></div>
                <img class="w-full h-fit" src="../home/img/grandjouney.png">
                <h1 class="mobile_h1">Telegram Mini Game</h1>
                <span class="mobile_span">Where every game leads to an airdrop adventure
                  <br> Play and trade using TG Game Bot</span>
                <button class="mobile_button">Explore Game</button>
                <!-- <div class="h-[10%]"></div> -->
              </div>
            </div>
            <div v-if="isLandscape" class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-center">
              <img src="./img/about_game/mockup.webp" class="w-[75%] translate-y-[3%]">
            </div>
            <div v-else class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start">
              <img src="./img/about_game/mockup.webp" class="w-[90%] translate-y-[-9%]">
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="w-full h-full flex justify-center items-center relative">
          <div class="w-full h-full flex flex-col xl:flex-row">
            <div class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start">
              <div v-if="isLandscape" class="h-full w-[80%] flex flex-col justify-center items-start gap-[1%]">
                <div class="h-[5%]"></div>
                <img class="w-[85%] h-fit" src="../home/img/grandjouney.png">
                <h1 class="desktop_h1">Play-To-Airdrop</h1>
                <span class="desktop_span">Where every game leads to an airdrop adventure <br>
                  Play and trade using TG Game Bot</span>
                <button class="desktop_button">Explore Game</button>
              </div>
              <div v-else class="h-full w-[90%] flex flex-col justify-center items-center">
                <div class="h-[10%]"></div>
                <img class="w-full h-fit" src="../home/img/grandjouney.png">
                <h1 class="mobile_h1">Play-To-Airdrop</h1>
                <span class="mobile_span">Where every game leads to an airdrop adventure
                  <br> Play and trade using TG Game Bot</span>
                <button class="mobile_button">Explore Game</button>
                <!-- <div class="h-[10%]"></div> -->
              </div>
            </div>
            <div v-if="isLandscape" class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-center">
              <!-- <img src="./img/about_game/airdrop.webp" class="w-[80%]"> -->
              <video class="w-[80%] object-cover" autoplay loop muted webkit-playsinline playsinline preload="metadata">
                <source src="./video/airdrop.webm" type="video/webm">
              </video>
            </div>
            <div v-else class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start">
              <img src="./img/about_game/airdrop.webp" class="w-[90%] translate-y-[-9%]">
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// Import required modules
import { Pagination, Autoplay } from 'swiper/modules';

export default {
  name: 'AboutGames',
  props: {
    msg: String
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },
  data() {
    return {
      previousIndex: 0,
      currentIndex: 0,
      currentRotation: 0,
    };
  },
  methods: {
    onSlideChange(swiper) {
      if (this.$refs.wheel_about_games) {
        this.currentIndex = swiper.realIndex;
        if (this.currentIndex > this.previousIndex) {
          this.currentRotation -= 45;
        } else if (this.currentIndex < this.previousIndex) {
          this.currentRotation += 45;
        }
        this.currentRotation = this.currentRotation % 360;
        this.$refs.wheel_about_games.style.transform = `translateX(-50%) translateY(50%) rotate(${this.currentRotation}deg)`;
        this.previousIndex = this.currentIndex;
      }
    }
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#aboutgames {
  background-image: url(./img/about_game/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  animation: bg-animation 30s infinite alternate;
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */


::v-deep .swiper-pagination-bullet {
  background: #f3b842;
  /* Default bullet color */
}

::v-deep .swiper-pagination-bullet-active {
  background: #ecca82;
  /* Active bullet color */
}

.desktop_h1 {
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-style: normal;
  color: #ff681e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 3vw;
  text-align: left;
}

.desktop_span {
  text-align: left;
  font-weight: 400;
  font-family: "Black Ops One", system-ui;
  font-size: 1.5vw;
  color: #e4c27e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.desktop_button {
  width: 35%;
  height: 6%;
  font-weight: 400;
  font-family: "Black Ops One", system-ui;
  font-size: 1.5vw;
  margin-top: 20px;
  border-radius: 20px;
  background: #ffe7bb;
  color: #ff681e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.mobile_h1 {
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-style: normal;
  color: #ff681e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 8.3vw;
  text-align: center;
  width: 100%;
}

.mobile_span {
  text-align: center;
  font-weight: 400;
  font-family: "Black Ops One", system-ui;
  font-size: 3vw;
  color: #e4c27e;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
}

.mobile_button {
  width: 50%;
  height: 10%;
  font-weight: 400;
  font-family: "Black Ops One", system-ui;
  font-size: 4vw;
  margin-top: 20px;
  border-radius: 20px;
  background: #ffe7bb;
  color: #ff681e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#minigame_list {
  bottom: calc(100vw * 0.08);
}

.wheel {
  width: calc(100vw * 0.2);
  transform: translateX(-50%) translateY(50%);
  transition: all 1s ease;
}
</style>
