<template>
    <div id="notice"
        class="backdrop-blur-lg w-full h-full fixed overflow-hidden flex justify-center items-center flex-col"
        style="z-index: 9999;">
        <div class="absolute h-full w-full left-0 top-0 flex justify-center items-center">
            <img src="../assets/img/notice.png" class="w-[60%] h-auto">
        </div>
        <h1 id="notice_mess" class="z-10"></h1>
    </div>
</template>

<script>
export default {
    name: 'NoticePage',
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#notice {
    transition: opacity 0.3s ease-out;
    display: none;
}


#notice_mess {
    font-size: 40px;
    font-family: "Black Ops One", system-ui;
    font-weight: 400;
    font-style: normal;
    color: #ffaa00;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
}
</style>