<template>
  <div id="aboutus" class="w-screen h-screen relative overflow-hidden">
    <img v-if="isLandscape" id="background" src="./img/backaboutwar.webp"
      class="absolute w-full h-full transform scale-125">
    <div class="w-full h-full flex justify-center items-center relative">
      <div class="w-full h-full flex flex-col xl:flex-row">
        <div id="title_about_desktop" v-if="isLandscape"
          class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-start relative">
          <img src="./img/titleabout.webp" class="h-[80%]">
        </div>
        <div id="title_about_mobile" v-else class="w-full xl:w-1/2 h-1/2 xl:h-full flex justify-center items-center">
          <img src="./img/titleabout.webp" class="w-[85%]">
        </div>
        <div class="w-full xl:w-1/2 h-1/2 xl:h-full flex items-center justify-center">
          <div id="data_analytic" v-if="isLandscape" class="w-full h-full flex items-center justify-start flex-col">
            <div class="h-[23%]"></div>
            <img src="./img/titleanalytics.webp" class="w-full">
            <div class="h-[5%]"></div>
            <div class="w-full h-[10%] flex justify-center items-center gap-[7%]">
              <div class="relative w-[30%] h-full bg-[#ff681e] -skew-x-12">
                <div class="analytic-card-overlay flex flex-col justify-center items-start">
                  <h1>{{ botUsers }}</h1>
                  <span>Bot Users</span>
                </div>
              </div>
              <div class="relative w-[30%] h-full bg-[#ff681e] -skew-x-12">
                <div class="analytic-card-overlay flex flex-col justify-center items-start">
                  <h1>{{ dailyUser }}</h1>
                  <span>Daily Users</span>
                </div>
              </div>
            </div>
            <div class="h-[3%]"></div>
            <div class="w-full h-[10%] flex justify-center items-center gap-[7%]">
              <div class="relative w-[30%] h-full bg-[#ff681e] -skew-x-12">
                <div class="analytic-card-overlay flex flex-col justify-center items-start">
                  <h1>{{ onchainUser }}</h1>
                  <span>Onchain Users</span>
                </div>
              </div>
              <div class="relative w-[30%] h-full bg-[#ff681e] -skew-x-12 mr-[5%]">
                <div class="analytic-card-overlay flex flex-col justify-center items-start">
                  <h1>{{ onchainTransaction }}</h1>
                  <span>Onchain Transaction</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="model_3d" ref="container" class="w-full h-full absolute left-0 top-0 z-0"></div>
      <div v-if="isLandscape" ref="actionMouse" class="absolute w-full h-full flex justify-center items-center">
        <div class="w-[50%]"></div>
        <div class="w-[50%] h-full flex items-center justify-center">
          <button @click="openNewTab('https://t.me/grandjourneybot')" id="playOnTelegramBtn"
            class="bg-[#ff681e] mt-[35%]">PLAY ON TELEGRAM</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import * as THREE from 'three';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import phonix from '../../assets/models/scene.gltf';
import axios from 'axios';

export default {
  name: 'AboutUs',
  props: {
    msg: String
  },
  data() {
    return {
      botUsers: 0,
      dailyUser: 0,
      onchainUser: 0,
      onchainTransaction: 0
    };
  },
  mounted() {
    // setTimeout(this.init, 300);
    this.fetchAnalytics();
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },
  methods: {
    // async init() {
    //   const container = this.$refs.container;
    //   const scene = new THREE.Scene();
    //   const camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 0.1, 1000);
    //   camera.position.set(0, 0, 10);
    //   camera.lookAt(0, 0, 0);
    //   const renderer = new THREE.WebGLRenderer({ alpha: true });
    //   const orbit = new OrbitControls(camera, renderer.domElement);
    //   let mixer;
    //   renderer.setSize(container.clientWidth, container.clientHeight);

    //   let mouse = new THREE.Vector2();
    //   let target = new THREE.Vector2();
    //   let windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);
    //   let model;
    //   const loader = new GLTFLoader();
    //   const actionMouse = this.$refs.actionMouse;

    //   const loadModel = async () => {
    //     try {
    //       const gltf = await new Promise((resolve, reject) => {
    //         loader.load(
    //           phonix,
    //           resolve,
    //           undefined,
    //           reject
    //         );
    //       });

    //       container.appendChild(renderer.domElement);

    //       renderer.shadowMap.enabled = true;
    //       renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    //       renderer.toneMapping = THREE.ACESFilmicToneMapping;
    //       renderer.toneMappingExposure = 1;

    //       model = await gltf.scene;
    //       model.scale.set(0.3, 0.3, 0.3);
    //       model.position.set(0, -3.5, 0);
    //       scene.add(model);

    //       const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
    //       hemiLight.color.setHSL(0.6, 1, 0.6);
    //       hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    //       hemiLight.position.set(0, 50, 0);
    //       scene.add(hemiLight);

    //       const dirLight = new THREE.DirectionalLight(0xffffff, 5);
    //       dirLight.color.setHSL(0.1, 1, 0.95);
    //       dirLight.position.set(- 1, 1.75, 1);
    //       dirLight.position.multiplyScalar(30);
    //       scene.add(dirLight);

    //       mixer = new THREE.AnimationMixer(scene);
    //       const clip = gltf.animations[1];
    //       const action = mixer.clipAction(clip);
    //       action.timeScale = 0.2;
    //       action.play();
    //     } catch (error) {
    //       console.error('Error loading GLTF model', error);
    //     }
    //   }

    //   loadModel();

    //   orbit.minDistance = 10;
    //   orbit.maxDistance = 10;
    //   orbit.mouseButtons.LEFT = null;
    //   orbit.touches.ONE = null;

    //   const clock = new THREE.Clock();

    //   const onMouseMove = (event) => {
    //     mouse.x = (event.clientX - windowHalf.x);
    //     mouse.y = (event.clientY - windowHalf.y);

    //     const background = document.getElementById('background');
    //     if (background) {
    //       const { clientX: mouseX, clientY: mouseY } = event;
    //       const { width, height } = background.getBoundingClientRect();
    //       const tiltX = ((mouseY / height) - 0.5) * -10;
    //       const tiltY = ((mouseX / width) - 0.5) * -10;
    //       background.style.transform = `rotateX(${tiltX}deg) rotateY(${tiltY}deg) scale(1.25)`;
    //     }
    //   }

    //   if (actionMouse) {
    //     actionMouse.addEventListener('mousemove', onMouseMove, false);
    //   } else {
    //     container.addEventListener('mousemove', onMouseMove, false);
    //   }

    //   const update = () => {
    //     if (model) {
    //       target.x = (mouse.x - 1) * 0.002;
    //       target.y = (mouse.y - 1) * 0.002;
    //       model.rotation.x += 0.05 * (target.y - model.rotation.x);
    //       model.rotation.y += 0.05 * (target.x - model.rotation.y);
    //     }
    //   }

    //   const animate = () => {
    //     const delta = clock.getDelta();
    //     if (mixer) {
    //       mixer.update(delta);
    //     }
    //     update();
    //     requestAnimationFrame(animate);
    //     orbit.update();
    //     renderer.render(scene, camera);
    //   };
    //   animate();
    // },
    async fetchAnalytics() {
      try {
        const apiUrl = `https://grandjourney-f9f679650199.herokuapp.com/userInfo`;
        let response = await axios.get(apiUrl);
        if (response.data) {
          this.botUsers = response.data.totalUser;
          this.dailyUser = response.data.totalUserDaily;
          this.onchainUser = Math.ceil(this.botUsers / 8);
          this.onchainTransaction = response.data.totalTransaction;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    openNewTab(url) {
      window.open(url, '_blank');
    }
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#aboutus {
  background-image: url(./img/backaboutwar.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  animation: bg-animation 30s infinite alternate;
  perspective: 1000px;
}

#background {
  transition: transform 0.5s ease-out, box-shadow 0.5s ease-out;
  transform-origin: center;
  will-change: transform;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

#model_3d,
#title_about_desktop,
#title_about_mobile,
#data_analytic,
#playOnTelegramBtn {
  transition: all 1s ease;
}

#model_3d {
  transform: translateY(100dvh);
}

#title_about_desktop {
  transform: translateX(-100vw);
}

#title_about_mobile {
  transform: translateY(-100dvh);
}

#data_analytic,
#playOnTelegramBtn {
  transform: translateX(100vw);
}

.analytic-card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -2%;
  top: -6%;
  background-color: #ffe7bb;
}

.analytic-card-overlay h1 {
  padding-left: 10%;
  font-size: 200%;
  font-weight: 900;
  color: #ff681e;
}

.analytic-card-overlay span {
  padding-left: 10%;
  color: #ff681e;
}

button,
button::after {
  padding: 16px 20px;
  font-size: 18px;
  background: linear-gradient(45deg, transparent 5%, #ff681e 5%);
  border: 0;
  color: #ffe7bb;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 900;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #ffe7bb;
  outline: transparent;
  position: relative;
}

button::after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  content: "PLAY ON TELEGRAM";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #ffe7bb 3%, #ffe7bb 5%, #ff681e 5%);
  text-shadow: -3px -3px 0px #ff681e, 3px 3px 0px #ffe7bb;
  clip-path: var(--slice-0);
}

button:hover::after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }

  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }

  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }

  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }

  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }

  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }

  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }

  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }

  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }

  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}
</style>