<template>
  <div id="trailer" class="w-screen h-screen relative overflow-hidden">
    <div class="w-full h-full -z-50">
      <video class="w-full h-full object-cover" autoplay loop muted webkit-playsinline
        playsinline preload="metadata">
        <source v-if="isLandscape" src="../home/video/trailer.webm" type="video/webm">
        <source v-else src="../home/video/trailer_mobile.webm" type="video/webm">
        <source v-if="!isLandscape" src="../home/video/trailermobilemp4.mp4" type="video/mp4">
      </video>
    </div>
    <div id="play" class="z-0 h-40 w-40 absolute cursor-pointer">
    </div>
    <div id="player_parent"
      class="absolute z-[60] backdrop-blur-lg w-full h-full top-0 left-0 flex justify-center items-center">
      <div id="player_container" class="absolute w-[65%] aspect-video z-[70]">
        <div id="player" class="z-[70]"></div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#player_parent {
  display: none;
  transition: opacity 1s ease;
  opacity: 0;
}

#player_parent::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 61;
}

#play {
  background-image: url(./img/playButtonVideo.png);
  background-size: cover;
}

#player_container {
  pointer-events: none;
}

.play-btn-mobile:active {
  transform: translateX(-50%) scale(0.9) !important;
}

#play:active {
  transform: scale(0.9);
}

.play-btn-mobile {
  display: block;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);
  width: 60%;
  height: 150px;
  background-size: contain !important;
  ;
  background-repeat: no-repeat;
  background-position: center;
}

.player-container-mobile {
  width: 100%;
  pointer-events: auto !important;
}
</style>


<script>
import { onMounted, watch, ref } from 'vue'
import playBtn from './img/playButtonVideo.png';
import pauseBtn from './img/pauseicon.webp';
import playBtnMobile from './img/mobile/playtrailermobile.webp';

export default {
  name: 'HomeView',
  props: ['playerState',],

  methods: {

  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },



  setup(props) {

    const player = ref(null);

    function checkAspectRatio() {

      const container = document.getElementById('trailer');
      const playButton = document.getElementById('play');
      const player_parent = document.getElementById('player_parent');
      const player_container = document.getElementById('player_container');


      if (window.innerHeight >= window.innerWidth) {
        playButton.style.backgroundImage = 'url(' + playBtnMobile + ')';
        playButton.classList.add('play-btn-mobile');
        player_container.classList.add('player-container-mobile');
        playButton.addEventListener('click', function () {
          player_parent.style.display = 'flex';
          setTimeout(function () {
            player_parent.style.opacity = '1';
          }, 1000);
          player.value?.playVideo();

          player_parent.addEventListener('click', function () {
            player.value?.seekTo(0);
            player.value?.pauseVideo();
            player_parent.style.opacity = '0';
            setTimeout(function () {
              player_parent.style.display = 'none';
            }, 1000);
          });
        });
      } else {

        container.addEventListener('mouseover', function (event) {
          playButton.style.display = 'block';
          playButton.style.top = (event.clientY - playButton.offsetHeight / 2) + 'px';
          playButton.style.left = (event.clientX - playButton.offsetWidth / 2) + 'px';
        });

        container.addEventListener('mousemove', function (event) {
          playButton.style.top = (event.clientY - playButton.offsetHeight / 2) + 'px';
          playButton.style.left = (event.clientX - playButton.offsetWidth / 2) + 'px';
        });

        container.addEventListener('mouseout', function () {
          playButton.style.display = 'none';
        });

        let clickCount = 0;

        var canClick = true;
        playButton.addEventListener('click', function () {
          if (!canClick) return;
          canClick = false;
          setTimeout(function () {
            canClick = true;
          }, 2000);
          clickCount++;

          if (clickCount === 1) {
            player_parent.style.display = 'flex';
            setTimeout(function () {
              if (player.value) {
                player.value.playVideo();
              }
              player_parent.style.opacity = '1';
            }, 1000);
            playButton.style.zIndex = '9999';
            playButton.style.backgroundImage = 'url(' + pauseBtn + ')';
          } else if (clickCount === 2) {
            playButton.style.zIndex = '0';
            playButton.style.backgroundImage = 'url(' + playBtn + ')';
            player_parent.style.opacity = '0';
            if (player.value) {
              player.value.seekTo(0);
              player.value.pauseVideo();
            }
            setTimeout(function () {
              player_parent.style.display = 'none';
            }, 1000);
            clickCount = 0;
          }
        });
      }
    }


    watch(() => props.playerState, (newVal) => {
      if (!newVal) {
        if (player.value) {
          try {
            player.value.seekTo(0);
            player.value.pauseVideo();
          } catch (error) {
            console.log(error);
          }
          const player_parent = document.getElementById('player_parent');
          player_parent.style.opacity = '0';
          setTimeout(function () {
            player_parent.style.display = 'none';
          }, 1000);
        }
      }
    });



    function createPlayerYoutube() {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = function () {
        /* global YT */
        player.value = new YT.Player('player', {
          height: '100%',
          width: '100%',
          videoId: 'yPKW29eamqw',
        });
      }
    }

    onMounted(() => {

      checkAspectRatio();
      createPlayerYoutube();

    });
  },
}
</script>
