<template>
  <!-- main menu -->
  <NoticePage />
  <LoadingPage />
  <div id="nav" v-if="isLandscape" class="w-full fixed z-50 nav_background">

    <img src="./assets/img/gleft.svg" class="absolute left-0 top-0 gleft z-10">
    <img src="./assets/img/wheel.svg" class="absolute wheel z-0">
    <img src="./assets/img/knife.svg" class="absolute knife z-0">
    <img src="./assets/img/rum.svg" class="absolute rum z-0">

    <nav class="w-full h-full flex justify-center items-center gap-[calc(100vw*0.1)]">

      <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
      <div>
        <a @click="goToSection(2)">ABOUT</a>
      </div>
      <div>
        <a @click="goToSection(4)">TOKENOMICS</a>
      </div>
      <div>
        <img id="logo_nav" @click="goToSection(0)"
          class="h-full transform scale-150 hover:scale-[2] transition-transform origin-top cursor-pointer active:scale-[1.8]"
          src="./assets/img/logonavbar.png" alt="Logo">
      </div>
      <div>
        <!-- <a href="#">GAMES</a> -->
        <router-link to="/games">GAMES</router-link>
      </div>
      <div>
        <a href="#">WHITEPAPER</a>
      </div>
    </nav>
  </div>
  <!-- main menu mobile -->
  <div id="nav_mobile" v-else class="w-full fixed z-50 top-0 left-0">
    <div id="nav_mobile_link" class="w-full flex items-center">
      <div class="h-[85%] w-full flex flex-col justify-center items-center">
        <div class="h-[20%] w-full flex justify-center items-center relative">
          <img src="./assets/img/menu_mobile/pattern.webp" class="h-[80%]">
        </div>
        <div class="h-[20%] w-full flex justify-center items-center relative">
          <a @click="goToSection(2)" class="z-10">ABOUT</a>
          <img src="./assets/img/menu_mobile/highlighttextmobile.webp" class="absolute h-[60%] z-0">
        </div>
        <div class="h-[20%] w-full flex justify-center items-center">
          <a @click="goToSection(4)">TOKENOMICS</a>
        </div>
        <div class="h-[20%] w-full flex justify-center items-center">
          <!-- <a href="#">GAMES</a> -->
          <router-link to="/games">GAMES</router-link>
        </div>
        <div class="h-[20%] w-full flex justify-center items-center">
          <a href="#">WHITEPAPER</a>
        </div>
      </div>
    </div>
    <div id="nav_mobile_banner" class="relative w-full">
      <img src="./assets/img/menu_mobile/navbarmobile.webp" class="w-full">
      <img @click="toggleMenu" src="./assets/img/logonavbar.png"
        class="absolute h-full origin-top transform top-0 left-0 right-0 mx-auto active:scale-125 transition-all scale-150">
      <img src="./assets/img/menu_mobile/patternnavbar.webp" class="absolute w-[32%] top-[-17%] left-0 right-0 mx-auto">
    </div>
  </div>
  <!-- left nav -->
  <div id="left_nav" class="fixed h-full z-50 left-0 flex items-center">
    <div class="h-[65%] w-full flex items-center justify-start flex-col">
      <div id="sound_btn" class="w-full aspect-square relative">
        <img src="./assets/img/colume.svg" class="w-full transform scale-x-[-1]">
        <img id="sound_vfx" src="./assets/img/frameoff.webp" class="w-[80%] absolute top-1 frame_btn" title="Sound">
      </div>
      <div class="w-full aspect-square relative">
        <img @click="goToSection(7)" src="./assets/img/flagc4kleft.png"
          class="w-full hover:scale-150 origin-top-left transition-all">
      </div>
    </div>
  </div>
  <!-- right nav -->
  <div id="right_nav" v-if="isRightNavVisible" class="fixed h-full w-24 z-50 right-0 flex items-center justify-end">

    <div class="absolute w-full h-full top-0 left-0 flex justify-center items-end flex-col gap-1">

      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/home.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/trailer.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/about.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/aboutgame.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/tokenomics.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon//roadmap.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/partnerlist.webp" class="tooltipimage">
      </div>
      <div class="w-full h-fit flex justify-end relative tooltip nav_item_container">
        <div class="rounded-full bg-[#d1b988] mr-3 nav_item"></div>
        <img src="./assets/img/icon/aboutc4kmedia.webp" class="tooltipimage">
      </div>

    </div>

    <!-- <div class="h-[70%] nav_left_btn flex items-center justify-start flex-col">
      <div id="sound_btn" class="w-full aspect-square relative">
        <img src="./assets/img/colume.svg" class="w-full">
        <img id="sound_vfx" src="./assets/img/frameoff.svg" class="w-[80%] absolute top-1 left-1 frame_btn"
          title="Sound">
      </div>
      <div class="w-full aspect-square relative">
        <img @click="goToSection(7)" src="./assets/img/flagc4k.png"
          class="w-full hover:scale-150 origin-top-right transition-all">
      </div>
    </div> -->
  </div>

  <!-- bottom nav -->
  <div id="bottom_nav" class="fixed w-full left-0 bottom-0 z-50 flex justify-center">
    <div id="bottom_nav_container" class="h-full w-[98%] left-0 bottom-0 flex justify-end items-center gap-2">
      <img @click="openNewTab('https://t.me/Group_GrandJourney')" src="./assets/img/icon/telegram.webp" class="h-[70%]"
        alt="telegram_group">
      <img @click="openNewTab('https://t.me/Group2_GrandJourney')" src="./assets/img/icon/telegram.webp" class="h-[70%]"
        alt="telegram_group">
      <img @click="openNewTab('https://t.me/Channel_GrandJourney')" src="./assets/img/icon/telegram.webp" class="h-[70%]" alt="telegram_channel">
      <img @click="openNewTab('https://x.com/journeygrand')" src="./assets/img/icon/x.webp" class="h-[70%]" alt="x">
      <img src="./assets/img/icon/facebook.webp" class="h-[70%]" alt="facebook">
      <img  @click="openNewTab('https://www.youtube.com/@grandjourneygame')" src="./assets/img/icon/youtube.webp" class="h-[70%]" alt="youtube">
      <img src="./assets/img/icon/tiktok.webp" class="h-[70%]" alt="tiktok">
    </div>
  </div>

  <!-- audio background -->
  <audio id="sound" loop>
    <source src="./assets/sound/caribbean.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>
  <router-view :parent-current-section="parentCurrentSection" :active-scroll="activeScroll" />
</template>

<style scoped>
#nav {
  height: calc(100vw*0.045);
}

#nav_mobile {
  /* height: calc(100vw * 0.13 + 250px); */
  height: fit-content;
  transition: all 0.5s ease;
  transform: translateY(calc(100vw * -0.59 - 1px));
}

#nav_mobile::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  backdrop-filter: blur(10px);
  height: 200dvh;
  width: 100vw;
  z-index: -1;
  display: none;
}

#nav_mobile.show_nav::before {
  display: block;
}

#nav_mobile_banner {
  height: calc(100vw * 0.13);
}

#nav_mobile_link {
  height: calc(100vw * 0.59);
  /* height: 250px; */
  /* background: linear-gradient(to bottom, #603813, #603813, #322921); */
  background-image: url(./assets/img/menu_mobile/framenavbar.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

#nav_mobile_link a {
  font-size: calc(100vw*0.04);
  font-weight: bolder;
  color: #dec07f;
  text-shadow: 1px 1px 3px #000000;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
}

#left_nav {
  width: calc(100vw*0.05);
}

.nav_background {
  background-image: url(./assets/img/backnav.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
}

nav div {
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: calc(100vw*0.01);
}

nav div:nth-child(3) {
  height: 100%;
  padding-top: 0;
}

nav div:not(:nth-child(3)) a {
  color: #d1b988;
}

nav div:not(:nth-child(3)) a:hover {
  color: #fce6ba;
}

#sound_btn {
  perspective: 100px;
}

.frame_btn {
  display: block;
  transition: transform .2s;
  animation: frame-rotate 2s ease-in-out infinite;
  transform-origin: top center;
  left: calc(100vw * 0.008);
}

@keyframes frame-rotate {
  0% {
    transform: rotateX(0);
  }

  50% {
    transform: rotateX(-5deg);
  }

  100% {
    transform: rotateX(0);
  }
}

.frame_btn:hover {
  animation: none;
  transform: rotateX(0deg);
}

a {
  font-size: calc(100vw*0.01);
}

.gleft {
  height: calc(100vw*0.015);
}

.wheel {
  height: calc(100vw*0.06);
  left: calc(100vw*-0.025);
  top: calc(100vw*-0.025);
  animation: wheel-rotate 5s linear infinite;
}

@keyframes wheel-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.knife {
  height: calc(100vw*0.04);
  bottom: calc(100vw*-0.022);
  left: calc(100vw*0.01);
  animation: knife-rotate 2s ease-in-out infinite;
  transform-origin: left;
}

@keyframes knife-rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.rum {
  height: calc(100vw*0.055);
  right: 0;
  animation: rum-rotate 2s ease-in-out infinite;
  transform-origin: right top;
}

@keyframes rum-rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#nav a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
}

#nav a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-image: url('./assets/img/linetext.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform .3s;
}

#nav a:hover::after {
  transform: scaleX(1);
}

.nav_item {
  width: calc(100vw*0.005/var(--devicePixelRatio));
  height: calc(100vw*0.018/var(--devicePixelRatio));
  transition: background-color 0.5s ease;
}

.nav_item:hover {
  transform: scale(1.1);
}

.nav_item:active {
  transform: scale(1);
}

.nav_left_btn {
  width: calc(100dvh*0.15/var(--devicePixelRatio));
}

.tooltip .tooltipimage {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  right: calc(100vw*0.02);
  height: 100%;
  aspect-ratio: 1/1;
  transform-origin: right center;
  transform: scale(2);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.tooltip:hover .tooltipimage {
  visibility: visible;
  opacity: 1;
}

.tooltip:active .tooltipimage {
  transform: scale(1.8);
}

#bottom_nav {
  height: calc(100vw * 0.035);
  background-image: url(./assets/img/menu_mobile/framesocial.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

#bottom_nav img {
  transform-origin: bottom center;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media (hover :hover) {
  #bottom_nav img:hover {
    transform: scale(1.2);
  }
}

#bottom_nav img:active {
  transform: scale(0.9);
}
</style>

<script setup>
import { onMounted, provide, ref } from 'vue'
import frameOn from './assets/img/frameon.webp'
import frameOff from './assets/img/frameoff.webp'
import LoadingPage from './views/Loading.vue'
import NoticePage from './views/Notice.vue'
import { useRoute } from 'vue-router';

//assets mobile
import islandmobile from './assets/img/loading/mobile/islandmobile.webp'
import moutainaftermobile from './assets/img/loading/mobile/moutainaftermobile.webp'
import muontainbackleftmobile from './assets/img/loading/mobile/muontainbackleftmobile.webp'
import rockrightmobile from './assets/img/loading/mobile/rockrightmobile.webp'
import skymobile from './assets/img/loading/mobile/skymobile.webp'
import treemobile from './assets/img/loading/mobile/treemobile.webp'
import watermobile from './assets/img/loading/mobile/watermobile.webp'

const route = useRoute();
let isLoading = true;
const isLoadingState = ref(null);

provide('isLoadingGlobal', isLoadingState);

function animateLoadingText() {
  const loadingText = document.getElementById('loading_text');
  let progress = 0;
  const stopAt = Math.floor(Math.random() * 31) + 60;

  const intervalId = setInterval(() => {
    if (!isLoading || progress >= stopAt) {
      clearInterval(intervalId);
      return;
    }

    loadingText.innerText = `LOADING ${progress}%`;
    progress++;
  }, 10);
}


function handleBannerAnimations(active) {
  if (route.path === "/") {
    var rocks = document.getElementById("rocks");
    var beach = document.getElementById("beach");
    var title = document.getElementById("title");

    if (active) {
      rocks.style.transform = "translateY(0)";
      setTimeout(function () {
        beach.style.transform = 'translateY(-5dvh)';
      }, 100);
      setTimeout(function () {
        beach.style.transform = 'translateY(0)';
      }, 1200);
      setTimeout(function () {
        title.style.transform = 'translateY(0)';
      }, 500);
    } else {
      rocks.style.transform = "translateY(100dvh)";
      beach.style.transform = 'translateY(100dvh)';
      title.style.transform = 'translateY(-100dvh)';
    }
  }
}

function loading100() {

  let loading = document.getElementById('loading');
  let loadingText = document.getElementById('loading_text');
  let currentPercentage = parseInt(loadingText.innerText.match(/\d+/));
  let loadingInterval = setInterval(function () {
    if (!currentPercentage) {
      currentPercentage = 0;
    }
    currentPercentage++;
    loadingText.innerText = 'LOADING ' + currentPercentage + '%';
    if (currentPercentage >= 100) {
      animateLoading();
      clearInterval(loadingInterval);
      setTimeout(function () {
        handleBannerAnimations(false);
      }, 1000);
      setTimeout(function () {
        handleBannerAnimations(false);
        loading.style.opacity = '0';
        setTimeout(() => {
          loading.style.display = 'none';
          isLoadingState.value = false;
          handleBannerAnimations(true);
        }, 300);
      }, 5000);
    }
  }, 10);
}


function hideLoading() {
  isLoadingState.value = true;
  setTimeout(function () {
    animateLoadingText();
  }, 1000);
  window.addEventListener("load", function () {
    stopLoading();
  });
}

function stopLoading() {
  isLoading = false;
  loading100();
}

function FireFox() {
  let body = document.querySelector('body');
  if (navigator.userAgent.includes('Firefox')) {
    body.style.overflow = 'hidden';
  }
}

function checkAspectRatio() {
  const bottom_nav_container = document.getElementById('bottom_nav_container');
  const right_nav = document.getElementById('right_nav');
  const left_nav = document.getElementById('left_nav');
  const bottom_nav = document.getElementById('bottom_nav');


  const ship = document.querySelector('.ship');
  const skysunset = document.getElementById('skysunset');
  const water = document.getElementById('water');
  const mountainleft = document.getElementById('mountainleft');
  const mountainright = document.getElementById('mountainright');
  const island = document.getElementById('island');
  const rockright = document.getElementById('rockright');
  const tree = document.getElementById('tree');
  const leaf = document.getElementById('leaf');
  const mountainafterleft = document.getElementById('mountainafterleft');
  const water2 = document.getElementById('water2');



  if (window.innerHeight >= window.innerWidth) {
    bottom_nav_container.style.justifyContent = 'center';
    right_nav.style.display = 'none';
    left_nav.style.display = 'none';
    bottom_nav.style.height = 'calc(100vw * 0.15)';

    ship.style.width = 'calc(100vw*0.6)';
    ship.style.marginTop = '300px';
    leaf.style.display = 'none';
    skysunset.src = skymobile;
    water.src = watermobile;
    water2.style.display = 'block';
    mountainleft.src = muontainbackleftmobile;
    island.src = islandmobile;
    rockright.src = rockrightmobile;
    tree.src = treemobile;
    mountainafterleft.src = moutainaftermobile;
    mountainright.style.display = 'none';
  } else {
    bottom_nav.style.backgroundImage = 'none';
  }
}

function animateLoading() {
  const skysunset = document.getElementById('skysunset');
  const water = document.getElementById('water');
  const mountainleft = document.getElementById('mountainleft');
  const ship = document.getElementById('ship');
  const mountainright = document.getElementById('mountainright');
  const island = document.getElementById('island');
  const rockright = document.getElementById('rockright');
  const tree = document.getElementById('tree');
  const leaf = document.getElementById('leaf');
  const mountainafterleft = document.getElementById('mountainafterleft');
  const water2 = document.getElementById('water2');

  if (window.innerHeight >= window.innerWidth) {
    tree.style.transform = `translateX(-100vw) scale(3)`;
    leaf.style.transform = `translateX(-100vw) scale(3)`;
    island.style.transform = `translateX(-100vw) scale(2)`;
    rockright.style.transform = `translateX(100vw) scale(2)`;
    mountainleft.style.transform = `translateX(-5vw) scale(1.5)`;
    mountainright.style.transform = `translateX(400vw) scale(3)`;
    mountainafterleft.style.transform = `translateX(5vw) scale(1.5)`;
    skysunset.style.transform = 'scale(1)';
    water.style.transform = 'scale(2)';
    water2.style.transform = 'scale(2)';
    ship.style.transform = `translateX(-10vw) scale(1.3) translateY(3vw)`;
  } else {
    tree.style.transform = `translateX(-100vw) scale(3)`;
    leaf.style.transform = `translateX(-100vw) scale(3)`;
    island.style.transform = `translateX(-100vw) scale(2)`;
    rockright.style.transform = `translateX(100vw) scale(2)`;
    mountainleft.style.transform = `translateX(-20vw) scale(2)`;
    mountainright.style.transform = `translateX(400vw) scale(3)`;
    mountainafterleft.style.transform = `translateX(5vw) scale(1.5)`;
    skysunset.style.transform = 'scale(1)';
    water.style.transform = 'scale(2)';
    ship.style.transform = `translateX(-10vw) scale(1.3) translateY(3vw)`;
  }

}


onMounted(() => {

  checkAspectRatio();
  FireFox();
  hideLoading();

  document.documentElement.style.setProperty('--devicePixelRatio', window.devicePixelRatio);
  var sound = document.getElementById("sound");
  var btn = document.getElementById("sound_btn");
  var isPlaying = false;
  var sound_vfx = document.getElementById("sound_vfx");

  btn.addEventListener("click", function () {
    if (isPlaying) {
      sound.pause();
      isPlaying = false;
      sound_vfx.src = frameOff;
    } else {
      sound.play();
      isPlaying = true;
      sound_vfx.src = frameOn;
    }
  });

});
</script>

<script>
export default {
  components: {
    LoadingPage,
    NoticePage,
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    },
    isRightNavVisible() {
      return this.$route.path === '/';
    }
  },
  data() {
    return {
      parentCurrentSection: -1,
      isMenuMobileOpen: false,
      activeScroll: null,
    };
  },
  watch: {
    '$route.path'(newPath) {
      this.updateRightNavVisibility(newPath);
    }
  },
  methods: {
    goToSection(index) {
      const href = window.location.href;
      const domain = window.location.hostname;
      const desiredPath = `${window.location.protocol}//${domain}`;

      if (href !== desiredPath) {
        this.$router.push('/').then(() => {
          this.performPostNavigationActions(index);
        });
      } else {
        this.performPostNavigationActions(index);
      }
    },
    performPostNavigationActions(index) {
      this.parentCurrentSection = index;
      window.sessionStorage.setItem('currentSection', index);
      this.$nextTick(() => {
        const sections = document.querySelectorAll("section");
        if (sections[index]) {
          window.scrollTo({
            top: sections[index].offsetTop,
            behavior: 'smooth'
          });
        }
      });
    },
    updateRightNavVisibility(path) {
      const rightNav = document.getElementById('right_nav');
      if (rightNav) {
        rightNav.style.display = path === '/' ? 'block' : 'none';
      }
    },
    toggleMenu() {
      const nav_mobile = document.getElementById('nav_mobile');
      if (this.isMenuMobileOpen) {
        nav_mobile.style.transform = 'translateY(calc(100vw * -0.59))';
        nav_mobile.classList.remove('show_nav');
        this.isMenuMobileOpen = false;
        this.activeScroll = true;
      } else {
        nav_mobile.style.transform = 'translateY(0)';
        nav_mobile.classList.add('show_nav');
        this.isMenuMobileOpen = true;
        this.activeScroll = false;
      }
    },
    openNewTab(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
